import authAxios from 'src/modules/shared/axios/authAxios';
import { tenantSubdomain } from './tenantSubdomain';
import AuthCurrentTenant from '../auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import { getConfig } from 'src/config';
import SettingsService from '../settings/settingsService';

export default class TenantService {
  static async fetchAndApply() {
    const tenantUrl = tenantSubdomain.fromLocationHref();

    if (
      tenantSubdomain.isEnabled &&
      tenantSubdomain.isRootDomain
    ) {
      AuthCurrentTenant.clear();
      return;
    }

    // If there is a subdomain with the tenant url,
    // it must fetch the settings form that subdomain no matter
    // which one is on local storage
    if (tenantUrl) {
      let currentTenant;
      try {
        currentTenant = await this.findByUrl(tenantUrl);
      } catch (error) {
        console.error(error);
      }

      AuthCurrentTenant.set(currentTenant);

      if (!currentTenant) {
        window.location.href = `${
          getConfig().REACT_APP_FRONTEND_PROTOCOL
        }://${getConfig().REACT_APP_FRONTEND_HOST}`;
        return;
      }
    }

    const tenantId = AuthCurrentTenant.get();
    if (tenantId && !tenantUrl) {
      try {
        const currentTenant = await this.find(tenantId);

        AuthCurrentTenant.set(currentTenant);
      } catch (error) {
        console.error(error);
      }
    }

    SettingsService.applyThemeFromTenant();
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/tenant/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/tenant`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/tenant`, body);

    return response.data;
  }

  static async acceptInvitation(
    token,
    forceAcceptOtherEmail = false,
  ) {
    const response = await authAxios.post(
      `/tenant/invitation/${token}/accept`,
      {
        forceAcceptOtherEmail,
      },
    );

    return response.data;
  }

  static async declineInvitation(token) {
    const params = null;

    const response = await authAxios.delete(
      `/tenant/invitation/${token}/decline`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Full_Tenant_Data() {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/Get_Full_Tenant_Data`,
    );
    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/tenant/${id}`);
    return response.data;
  }

  static async findByUrl(url) {
    const response = await authAxios.get(`/tenant/url`, {
      params: { url },
    });
    return response.data;
  }

  static async fetchAllTenants(
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/full_tenant`,
      {
        params,
      },
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/tenant`, {
      params,
    });

    return response.data;
  }

  static async tenant_List(
    type,
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const id = AuthCurrentTenant.get();
    const tenant = AuthCurrentTenant.getTenant();

    const params = {
      type,
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(
      `/tenant/${id}/tenant_List`,
      {
        params,
      },
    );

    return response.data;
  }

  static async tenant_List_Platform(
    type,
    filter,
    orderBy,
    limit,
    offset,
    platform,
  ) {
    const id = AuthCurrentTenant.get();
    const tenant = AuthCurrentTenant.getTenant();

    const params = {
      type,
      filter,
      orderBy,
      limit,
      offset,
      platform: platform,
    };

    const response = await authAxios.get(
      `/tenant/${id}/tenant_List`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Will_Warning_Check(tenant_id) {
    const response = await authAxios.post(
      `/tenant/${tenant_id}/Will_Warning_Check`,
      {
        tenant_id,
      },
    );

    return response.data;
  }

  static async listLocation(
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantLocation`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listLocationAutoComplete() {
    const tenantId = AuthCurrentTenant.get();
    const params = {
      tenant_id: tenantId,
    };

    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantLocationAutoComplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async findLocationById(id) {
    const params = {
      id,
    };
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantLocationById`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listContact(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantContact`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listContactAutoComplete(
    query,
    limit,
    filter = [],
  ) {
    const params = {
      query,
      limit,
      filter,
    };
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantContactAutoComplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async findContactById(id) {
    const params = {
      id,
    };
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantContactById`,
      {
        params,
      },
    );

    return response.data;
  }

  static async tenant_Find_Type(type, platform) {
    const id = AuthCurrentTenant.get();

    const params = {
      type,
      platform: platform,
    };

    const response = await authAxios.get(
      `/tenant/${id}/tenant_Find_Type`,
      {
        params,
      },
    );

    return response.data;
  }

  static async tenant_Find(type, arrayId) {
    const id = AuthCurrentTenant.get();

    const params = {
      type,
      arrayId,
    };

    const response = await authAxios.get(
      `/tenant/${id}/tenant_Find`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Update_Tenant_Data(data) {
    const body = {
      data,
    };

    const id = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${id}/tenant_Update`,
      body,
    );

    return response.data;
  }

  static async Update_Tenant_Specific_Data(data) {
    const body = {
      data,
    };

    const id = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${id}/tenant_Update_Specific`,
      body,
    );

    return response.data;
  }

  static async Delete_Tenant_Data(data) {
    const body = {
      data,
    };

    const id = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${id}/tenant_Delete`,
      body,
    );

    return null;
  }

  static async Get_Asset_Distribution() {
    const id = AuthCurrentTenant.get();

    const params = {
      tenantId: id,
    };

    const response = await authAxios.get(
      `/tenant/${id}/Asset_Distribution`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Tenant_Data(
    newTenantId,
    newUserId,
    report_type,
  ) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
      reportType: report_type,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Tenant_Data`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Will_Data(newTenantId, newUserId) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Will_Data`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Estate_Data(newTenantId, newUserId) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Estate_Data`,
      {
        params,
      },
    );

    return response.data;
  }

  static async Get_Pengisytiharan_Harta_Sepencarian(
    newTenantId,
    newUserId,
    spouseId,
  ) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
      spouseId: spouseId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/Get_Pengisytiharan_Harta_Sepencarian`,
      {
        params,
      },
    );

    return response.data;
  }
}
