import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class assistantService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/assistant/${id}`,
      body,
    );

    return response.data;
  }

  static async updateWithSpecificTenant(
    crossTenant,
    tenantId,
    assistantId,
    data,
  ) {
    const body = {
      crossTenant,
      assistantId,
      data,
    };

    const response = await authAxios.put(
      `/tenant/${tenantId}/assistant/${assistantId}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/assistant`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/assistant`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/assistant/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    console.log('find assistant');
    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant/${tenantId}`,
    );

    console.log(response);

    return response.data;
  }

  static async findByEmail(email) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-email/${email}`,
    );

    return response.data;
  }

  static async findByEmailTenant(email) {
    // console.log('findByEmailTenant', email);
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-email-tenant/${email}`,
    );
    // console.log('findByEmailTenant', response.data);

    return response.data;
  }

  static async findByAssistantEmail(email) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-assistemail/${email}`,
    );

    return response.data;
  }

  static async findByUserId(userid) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-user/${userid}`,
    );

    return response.data;
  }

  static async findByUserIDAndTestatorID(
    userid,
    testatorid,
  ) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-usertestator/${userid}/${testatorid}`,
    );

    return response.data;
  }

  static async findByTestatorId(testatorid) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-testator/${testatorid}`,
    );

    return response.data;
  }

  static async findByExecutorId(executorid) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-executor/${executorid}`,
    );

    return response.data;
  }

  static async findByLegalGuardianId(legalguardianid) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-legal-guardian/${legalguardianid}`,
    );

    return response.data;
  }

  static async findByBeneficiaryId(beneficiaryid) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-beneficiary/${beneficiaryid}`,
    );

    return response.data;
  }

  static async findByContactListId(contactlistid) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-contact-list/${contactlistid}`,
    );

    return response.data;
  }

  static async findByDigitaltId(digitalid) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-digital/${digitalid}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant`,
      {
        params,
      },
    );

    return response.data;
  }

  static Check_IsExists = (list, comparer) => {
    var result = false;

    list.forEach((item) => {
      if (item === comparer) {
        result = true;
      }
    });

    return result;
  };

  static async listAll(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/assistant-all`,
      {
        params,
      },
    );

    return response.data;
  }

  static async getAssistantPermission(
    tenantId,
    userId,
    target,
  ) {
    const params = {
      tenantId: tenantId,
      userId: userId,
      target: target,
    };

    const currentTenant = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${currentTenant}/assistant-get-permission/${tenantId}/${userId}/${target}`,
      {
        params,
      },
    );

    return response.data;
  }
}
