import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
} from '@mui/material';
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { SnackbarPortal } from './SnackbarPortal';
import { setGlobalOpenSnackbar } from './globalOpenSnackbar';

// Create a context for Snackbar
const SnackbarContext = createContext({
  openSnackbar: (
    message: string,
    severity: 'success' | 'error' | 'info' | 'warning',
  ) => {},
  closeSnackbar: () => {},
});

// Snackbar provider component
export function SnackbarProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('info');
  const [autoHideDuration, setAutoHideDuration] = useState(6000);
  // console.log('autoHideDuration', autoHideDuration);

  const openSnackbar = (
    message: string,
    severity: 'success' | 'error' | 'info' | 'warning',
    autoHideDuration: number = 6000,
  ) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
    setAutoHideDuration(autoHideDuration);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  // Set global access to openSnackbar
  React.useEffect(() => {
    setGlobalOpenSnackbar(openSnackbar);
  }, [openSnackbar]);

  return (
    <SnackbarContext.Provider
      value={{ openSnackbar, closeSnackbar }}
    >
      <SnackbarPortal>
        {children}
        <Snackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={(
            event: React.SyntheticEvent | Event,
            reason: SnackbarCloseReason,
          ) => {
            if (reason === 'clickaway') {
              return;
            }
            closeSnackbar();
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Alert
            onClose={closeSnackbar}
            severity={severity}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </SnackbarPortal>
    </SnackbarContext.Provider>
  );
}

// Custom hook for using Snackbar
export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      'useSnackbar must be used within a SnackbarProvider',
    );
  }
  return context;
}
