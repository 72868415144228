import { AuthToken } from 'src/modules/auth/authToken';
import Axios from 'axios';
import {getConfig} from 'src/config';
import { getLanguageCode } from 'src/i18n';
import Qs from 'qs';
import moment from 'moment';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import {sanitizeObject} from 'src/utils/sanitization';
import { getGlobalOpenSnackbar } from 'src/hooks/globalOpenSnackbar'

const authAxios = Axios.create({
  baseURL: getConfig().REACT_APP_BACKEND_URL,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (
          moment.isMoment(value) ||
          value instanceof Date
        ) {
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

authAxios.interceptors.request.use(
  async function (options) {
    // console.log("axios intercept")
    const token = AuthToken.get();

    if (options.data) {
      try {
        // console.log('options.data before sanitization', options.data);
        options.data = sanitizeObject(options.data);
        // console.log('options.data after sanitization', options.data);
      } catch (error) {
        if (error?.message === 'Suspicious data detected') {
          // console.log('error', error)
          // const { openSnackbar } = useSnackbar();
          const openSnackbar = getGlobalOpenSnackbar();
          // console.warn('Suspicious data detected. Request aborted.');
          openSnackbar(
            'The data you entered appears to be suspicious. Please try again.',
            'warning',
            15000,
          );
          const customError = new Error('Suspicious data detected');
          // customError?.status = 400; // Set the desired status code
          // Return a rejected promise to prevent the request from being sent
          return Promise.reject(customError);
        }
      }
    }

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    const isAssistantRemote = AuthCurrentTenant.isAssistant()

    if(isAssistantRemote){
      options.headers['Remote'] = `true`;
    }

    options.headers['Accept-Language'] = getLanguageCode();

    return options;
  },
  function (error) {
    // console.log('Request error: ', error);
    return Promise.reject(error);
  },
);

export default authAxios;
