import { getConfig } from 'src/config';
import Permissions from 'src/security/permissions';
import {sanitizeObject} from 'src/utils/sanitization';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/my-heir',
    loader: () => import('src/view/heir/list/HeirListPage'),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'myHeir',
  },
  {
    path: '/message',
    loader: () =>
      import('src/view/chatGPT/view/ChatGPTViewPage'),
    exact: true,
  },
  {
    path: '/my-family-tree',
    loader: () =>
      import('src/view/familyTree/page/FamilyTreePages'),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'myFamilyTree',
  },
  {
    path: '/faraid-calculator',
    loader: () =>
      import(
        'src/view/faraidCalculator/page/FaraidCalculatorPage'
      ),
    exact: true,
  },

  // #region Asset Distribution
  {
    path: '/asset-distribution',
    loader: () =>
      import(
        'src/view/assetDistribution/DistributionFormPage'
      ),
    permissionRequired: false,
    exact: true,
    section: 'assetsDistribution',
  },
  // {
  //   path: '/financial-planning',
  //   loader: () =>
  //     import(
  //       'src/view/financialPlanning/form/FinancialPlannerSite'
  //     ),
  //   exact: true,
  //   section: 'financialPlanning',
  // },
  // {
  //   path: '/financial-planning/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/financialPlanning/form/FinancialPlanningFormPage'
  //     ),
  //   permissionRequired: permissions.financialPlanningEdit,
  //   exact: true,
  //   section: 'financialPlanning',
  // },
  {
    path: '/financial-planning',
    loader: () =>
      import(
        'src/view/financialPlanning/form/FinancialPlanningFormPage'
      ),
    permissionRequired: permissions.financialPlanningCreate,
    exact: true,
    section: 'financialPlanning',
  },
  {
    path: '/family-tree',
    loader: () =>
      import('src/view/dashboard/shared/FamilyTree'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/signup-report',
    loader: () =>
      import('src/view/referralReport/SignUpReportPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/subscription-report',
    loader: () =>
      import(
        'src/view/referralReport/SubscriptionReportPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/sales-report',
    loader: () =>
      import('src/view/referralReport/SalesReportPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/',
    loader: () => import('src/view/dashboard/IndexPage'),
    permissionRequired: null,
    exact: true,
  },
  // {
  //   path: '/mmpage',
  //   loader: () => import('src/view/dashboard/MainPage'),
  //   permissionRequired: null,
  //   exact: true,
  // },
  {
    path: '/will-preview',
    loader: () => import('src/view/dashboard/WillPreview'),
    permissionRequired: null,
    exact: true,
  },
  // {
  //   path: '/digital-vault-home',
  //   loader: () =>
  //     import('src/view/dashboard/DigitalVaultDashboard'),
  //   permissionRequired: null,
  //   exact: true,
  // },
  {
    path: '/docview',
    loader: () =>
      import('src/view/docViewer/DocViewerPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/feature',
    loader: () =>
      import('src/view/dashboard/FeatureListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/willListPage',
    loader: () =>
      import('src/view/dashboard/BasicWillListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/hibahListPage',
    loader: () =>
      import('src/view/dashboard/BasicHibahListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/hartasepencarianListPage',
    loader: () =>
      import(
        'src/view/dashboard/BasicHartaSepencarianListPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/will-plan',
    loader: () => import('src/view/plan/Will_Plan_Page'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/assetListPage',
    loader: () =>
      import('src/view/dashboard/AssetListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/learnMore',
    loader: () =>
      import('src/view/guidancePage/UserGuide_Full'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
    step: 1,
    section: 'profile',
  },
  {
    path: '/profile-assistant',
    loader: () =>
      import('src/view/auth/ProfileAssistantFormPage'),
    permissionRequired: null,
    exact: true,
    // step: 1,
    section: 'profile',
  },
  {
    path: '/user-settings',
    loader: () =>
      import('src/view/userSettings/UserSettingsPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  getConfig().REACT_APP_IS_PLAN_ENABLED && {
    path: '/refresh_plan',
    loader: () => import('src/view/plan/will/Refresh_Plan'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  // {
  //   path: '/user',
  //   loader: () => import('src/view/user/list/UserPage'),
  //   permissionRequired: permissions.userRead,
  //   exact: true,
  // },

  {
    path: '/user/new-invitation',
    loader: () =>
      import('src/view/user/new/UserNewInvitationPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  // {
  //   path: '/user/new',
  //   loader: () => import('src/view/user/new/UserNewPage'),
  //   permissionRequired: permissions.userCreate,
  //   exact: true,
  // },

  // {
  //   path: '/user/importer',
  //   loader: () =>
  //     import('src/view/user/importer/UserImporterPage'),
  //   permissionRequired: permissions.userImport,
  //   exact: true,
  // },
  // {
  //   path: '/user/:id/edit',
  //   loader: () => import('src/view/user/edit/UserEditPage'),
  //   permissionRequired: permissions.userEdit,
  //   exact: true,
  // },
  // {
  //   path: '/user/:id',
  //   loader: () => import('src/view/user/view/UserViewPage'),
  //   permissionRequired: permissions.userRead,
  //   exact: true,
  // },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/beneficiary',
    loader: () =>
      import(
        'src/view/beneficiary/list/BeneficiaryListPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary/new',
    loader: () =>
      import(
        'src/view/beneficiary/form/BeneficiaryFormPage'
      ),
    permissionRequired: permissions.beneficiaryCreate,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary/importer',
    loader: () =>
      import(
        'src/view/beneficiary/importer/BeneficiaryImporterPage'
      ),
    permissionRequired: permissions.beneficiaryImport,
    exact: true,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary/:id/edit',
    loader: () =>
      import(
        'src/view/beneficiary/form/BeneficiaryFormPage'
      ),
    permissionRequired: permissions.beneficiaryEdit,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary/:id',
    loader: () =>
      import(
        'src/view/beneficiary/view/BeneficiaryViewPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    section: 'beneficiary',
  },

  {
    path: '/beneficiary_hibah',
    loader: () =>
      import(
        'src/view/beneficiary/list/BeneficiaryListPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_hibah/new',
    loader: () =>
      import(
        'src/view/beneficiary/form/BeneficiaryFormPage'
      ),
    permissionRequired: permissions.beneficiaryCreate,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_hibah/importer',
    loader: () =>
      import(
        'src/view/beneficiary/importer/BeneficiaryImporterPage'
      ),
    permissionRequired: permissions.beneficiaryImport,
    exact: true,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_hibah/:id/edit',
    loader: () =>
      import(
        'src/view/beneficiary/form/BeneficiaryFormPage'
      ),
    permissionRequired: permissions.beneficiaryEdit,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_hibah/:id',
    loader: () =>
      import(
        'src/view/beneficiary/view/BeneficiaryViewPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_harta',
    loader: () =>
      import(
        'src/view/beneficiary/list/BeneficiaryListPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_harta/new',
    loader: () =>
      import(
        'src/view/beneficiary/form/BeneficiaryFormPage'
      ),
    permissionRequired: permissions.beneficiaryCreate,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_harta/importer',
    loader: () =>
      import(
        'src/view/beneficiary/importer/BeneficiaryImporterPage'
      ),
    permissionRequired: permissions.beneficiaryImport,
    exact: true,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_harta/:id/edit',
    loader: () =>
      import(
        'src/view/beneficiary/form/BeneficiaryFormPage'
      ),
    permissionRequired: permissions.beneficiaryEdit,
    exact: true,
    step: 2,
    section: 'beneficiary',
  },
  {
    path: '/beneficiary_harta/:id',
    loader: () =>
      import(
        'src/view/beneficiary/view/BeneficiaryViewPage'
      ),
    permissionRequired: permissions.beneficiaryRead,
    exact: true,
    section: 'beneficiary',
  },

  // {
  //   path: '/executor',
  //   loader: () =>
  //     import('src/view/executor/list/ExecutorListPage'),
  //   permissionRequired: permissions.executorRead,
  //   exact: true,
  //   step: 4,
  //   section: 'executor',
  // },
  // {
  //   path: '/executor/new',
  //   loader: () =>
  //     import('src/view/executor/form/ExecutorFormPage'),
  //   permissionRequired: permissions.executorCreate,
  //   exact: true,
  //   section: 'executor',
  // },
  {
    path: '/executors/new',
    loader: () =>
      import('src/view/executor/main/ExecutorsFormPage'),
    permissionRequired: permissions.executorCreate,
    step: 4,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executors/:id/edit',
    loader: () =>
      import('src/view/executor/main/ExecutorsFormPage'),
    permissionRequired: permissions.executorEdit,
    step: 4,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executor/importer',
    loader: () =>
      import(
        'src/view/executor/importer/ExecutorImporterPage'
      ),
    permissionRequired: permissions.executorImport,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executor/:id/edit',
    loader: () =>
      import('src/view/executor/form/ExecutorFormPage'),
    permissionRequired: permissions.executorEdit,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executor/:id',
    loader: () =>
      import('src/view/executor/view/ExecutorViewPage'),
    permissionRequired: permissions.executorRead,
    exact: true,
    section: 'executor',
  },

  // {
  //   path: '/witness',
  //   loader: () =>
  //     import('src/view/witness/list/WitnessListPage'),
  //   permissionRequired: permissions.witnessRead,
  //   exact: true,
  //   step: 6,
  //   section: 'witness',
  // },
  // {
  //   path: '/witness/new',
  //   loader: () =>
  //     import('src/view/witness/form/WitnessFormPage'),
  //   permissionRequired: permissions.witnessCreate,
  //   exact: true,
  //   section: 'witness',
  // },
  {
    path: '/specific-gift',
    loader: () =>
      import(
        'src/view/specificGift/list/SpecificGiftListPage'
      ),
    permissionRequired: permissions.specificGiftRead,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift/new',
    loader: () =>
      import(
        'src/view/specificGift/form/SpecificGiftFormPage'
      ),
    permissionRequired: permissions.specificGiftCreate,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift/:id/edit',
    loader: () =>
      import(
        'src/view/specificGift/form/SpecificGiftFormPage'
      ),
    permissionRequired: permissions.specificGiftEdit,
    exact: true,
    section: 'specificGift',
  },

  {
    path: '/specific-gift_hibah',
    loader: () =>
      import(
        'src/view/specificGift/list/SpecificGiftListPage'
      ),
    permissionRequired: permissions.specificGiftRead,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift_hibah/new',
    loader: () =>
      import(
        'src/view/specificGift/form/SpecificGiftFormPage'
      ),
    permissionRequired: permissions.specificGiftCreate,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift_hibah/:id/edit',
    loader: () =>
      import(
        'src/view/specificGift/form/SpecificGiftFormPage'
      ),
    permissionRequired: permissions.specificGiftEdit,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift_harta',
    loader: () =>
      import(
        'src/view/specificGift/list/SpecificGiftListPage'
      ),
    permissionRequired: permissions.specificGiftRead,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift_harta/new',
    loader: () =>
      import(
        'src/view/specificGift/form/SpecificGiftFormPage'
      ),
    permissionRequired: permissions.specificGiftCreate,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/specific-gift_harta/:id/edit',
    loader: () =>
      import(
        'src/view/specificGift/form/SpecificGiftFormPage'
      ),
    permissionRequired: permissions.specificGiftEdit,
    exact: true,
    section: 'specificGift',
  },
  {
    path: '/witnesss/new',
    loader: () =>
      import('src/view/witness/main/WitnesssFormPage'),
    permissionRequired: permissions.witnessCreate,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witnesss/:id/edit',
    loader: () =>
      import('src/view/witness/main/WitnesssFormPage'),
    permissionRequired: permissions.witnessEdit,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness/importer',
    loader: () =>
      import(
        'src/view/witness/importer/WitnessImporterPage'
      ),
    permissionRequired: permissions.witnessImport,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness/:id/edit',
    loader: () =>
      import('src/view/witness/form/WitnessFormPage'),
    permissionRequired: permissions.witnessEdit,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness/:id',
    loader: () =>
      import('src/view/witness/view/WitnessViewPage'),
    permissionRequired: permissions.witnessRead,
    exact: true,
    section: 'witness',
  },
  {
    path: '/personal-message',
    loader: () =>
      import(
        'src/view/personalMessage/list/PersonalMessageListPage'
      ),
    permissionRequired: permissions.personalMessageRead,
    exact: true,
    section: 'lastInstructions',
    // step: 7,
  },
  {
    path: '/personal-message/new',
    loader: () =>
      import(
        'src/view/personalMessage/form/CreateLetterSteps/CreateLetterSteps'
      ),
    permissionRequired: permissions.personalMessageCreate,
    exact: true,
    section: undefined,
  },
  {
    path: '/personal-message/edit/:id',
    loader: () =>
      import(
        'src/view/personalMessage/form/CreateLetterSteps/CreateLetterSteps'
      ),
    permissionRequired: permissions.personalMessageCreate,
    exact: true,
    section: undefined,
  },
  {
    path: '/personal-message/:id/edit',
    loader: () =>
      import(
        'src/view/personalMessage/form/PersonalMessageFormPage'
      ),
    permissionRequired: permissions.personalMessageEdit,
    exact: true,
    section: 'lastInstructions',
  },
  {
    path: '/personal-message/:id',
    loader: () =>
      import(
        'src/view/personalMessage/view/PersonalMessageViewPage'
      ),
    permissionRequired: permissions.personalMessageRead,
    exact: true,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions',
    loader: () =>
      import(
        'src/view/lastInstructions/list/LastInstructionsListPage'
      ),
    permissionRequired: permissions.lastInstructionsRead,
    exact: true,
    // step: 7,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions/new',
    loader: () =>
      import(
        'src/view/lastInstructions/form/LastInstructionsFormPage'
      ),
    permissionRequired: permissions.lastInstructionsCreate,
    exact: true,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions/importer',
    loader: () =>
      import(
        'src/view/lastInstructions/importer/LastInstructionsImporterPage'
      ),
    permissionRequired: permissions.lastInstructionsImport,
    exact: true,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions/:id/edit',
    loader: () =>
      import(
        'src/view/lastInstructions/form/LastInstructionsFormPage'
      ),
    permissionRequired: permissions.lastInstructionsEdit,
    exact: true,
    section: 'lastInstructions',
  },
  {
    path: '/last-instructions/:id',
    loader: () =>
      import(
        'src/view/lastInstructions/view/LastInstructionsViewPage'
      ),
    permissionRequired: permissions.lastInstructionsRead,
    exact: true,
    section: 'lastInstructions',
  },

  {
    path: '/funeral-directives',
    loader: () =>
      import('src/view/dashboard/FuneralDirectivesPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/funeral-directives-information',
    loader: () =>
      import(
        'src/view/funeralDirectives/view/FuneralDirectivesPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/account',
    loader: () =>
      import('src/view/account/list/AccountListPage'),
    permissionRequired: permissions.accountRead,
    exact: true,
    // step: 4,
    section: 'account',
  },
  {
    path: '/account/new',
    loader: () =>
      import('src/view/account/form/AccountFormPage'),
    permissionRequired: permissions.accountCreate,
    exact: true,
    section: 'account',
  },
  {
    path: '/account/importer',
    loader: () =>
      import(
        'src/view/account/importer/AccountImporterPage'
      ),
    permissionRequired: permissions.accountImport,
    exact: true,
    section: 'account',
  },
  {
    path: '/account/:id/edit',
    loader: () =>
      import('src/view/account/form/AccountFormPage'),
    permissionRequired: permissions.accountEdit,
    exact: true,
    section: 'account',
  },
  {
    path: '/account/:id',
    loader: () =>
      import('src/view/account/view/AccountViewPage'),
    permissionRequired: permissions.accountRead,
    exact: true,
    section: 'account',
  },

  {
    path: '/property',
    loader: () =>
      import('src/view/property/list/PropertyListPage'),
    permissionRequired: permissions.propertyRead,
    exact: true,
    // step: 6,
    section: 'property',
  },
  {
    path: '/property/new',
    loader: () =>
      import('src/view/property/form/PropertyFormPage'),
    permissionRequired: permissions.propertyCreate,
    exact: true,
    section: 'property',
  },
  {
    path: '/property/importer',
    loader: () =>
      import(
        'src/view/property/importer/PropertyImporterPage'
      ),
    permissionRequired: permissions.propertyImport,
    exact: true,
    section: 'property',
  },
  {
    path: '/property/:id/edit',
    loader: () =>
      import('src/view/property/form/PropertyFormPage'),
    permissionRequired: permissions.propertyEdit,
    exact: true,
    section: 'property',
  },
  {
    path: '/property/:id',
    loader: () =>
      import('src/view/property/view/PropertyViewPage'),
    permissionRequired: permissions.propertyRead,
    exact: true,
    section: 'property',
  },

  {
    path: '/other-asset',
    loader: () =>
      import('src/view/otherAsset/list/OtherAssetListPage'),
    permissionRequired: permissions.otherAssetRead,
    exact: true,
    // step: 7,
    section: 'otherAsset',
  },
  {
    path: '/other-asset/new',
    loader: () =>
      import('src/view/otherAsset/form/OtherAssetFormPage'),
    permissionRequired: permissions.otherAssetCreate,
    exact: true,
    section: 'otherAsset',
  },
  {
    path: '/other-asset/importer',
    loader: () =>
      import(
        'src/view/otherAsset/importer/OtherAssetImporterPage'
      ),
    permissionRequired: permissions.otherAssetImport,
    exact: true,
    section: 'otherAsset',
  },
  {
    path: '/other-asset/:id/edit',
    loader: () =>
      import('src/view/otherAsset/form/OtherAssetFormPage'),
    permissionRequired: permissions.otherAssetEdit,
    exact: true,
    section: 'otherAsset',
  },
  {
    path: '/other-asset/:id',
    loader: () =>
      import('src/view/otherAsset/view/OtherAssetViewPage'),
    permissionRequired: permissions.otherAssetRead,
    exact: true,
    section: 'otherAsset',
  },

  // {
  //   path: '/cash',
  //   loader: () => import('src/view/cash/list/CashListPage'),
  //   permissionRequired: permissions.cashRead,
  //   exact: true,
  //   step: 6,
  //   section: 'cash',
  // },
  // {
  //   path: '/cash/new',
  //   loader: () => import('src/view/cash/form/CashFormPage'),
  //   permissionRequired: permissions.cashCreate,
  //   exact: true,
  //   section: 'cash',
  // },
  // {
  //   path: '/cash/importer',
  //   loader: () =>
  //     import('src/view/cash/importer/CashImporterPage'),
  //   permissionRequired: permissions.cashImport,
  //   exact: true,
  //   section: 'cash',
  // },
  // {
  //   path: '/cash/:id/edit',
  //   loader: () => import('src/view/cash/form/CashFormPage'),
  //   permissionRequired: permissions.cashEdit,
  //   exact: true,
  //   section: 'cash',
  // },
  // {
  //   path: '/cash/:id',
  //   loader: () => import('src/view/cash/view/CashViewPage'),
  //   permissionRequired: permissions.cashRead,
  //   exact: true,
  //   section: 'cash',
  // },

  {
    path: '/debt',
    loader: () => import('src/view/debt/list/DebtListPage'),
    permissionRequired: permissions.debtRead,
    exact: true,
    // step: 5,
    section: 'debt',
  },
  {
    path: '/debt/new',
    loader: () => import('src/view/debt/form/DebtFormPage'),
    permissionRequired: permissions.debtCreate,
    exact: true,
    section: 'debt',
  },
  {
    path: '/debt/importer',
    loader: () =>
      import('src/view/debt/importer/DebtImporterPage'),
    permissionRequired: permissions.debtImport,
    exact: true,
    section: 'debt',
  },
  {
    path: '/debt/:id/edit',
    loader: () => import('src/view/debt/form/DebtFormPage'),
    permissionRequired: permissions.debtEdit,
    exact: true,
    section: 'debt',
  },
  {
    path: '/debt/:id',
    loader: () => import('src/view/debt/view/DebtViewPage'),
    permissionRequired: permissions.debtRead,
    exact: true,
    section: 'debt',
  },

  // {
  //   path: '/residuary',
  //   loader: () =>
  //     import('src/view/residuary/list/ResiduaryListPage'),
  //   permissionRequired: permissions.residuaryRead,
  //   exact: true,
  //   step: 3,
  //   section: 'distribution',
  // },
  {
    path: '/residuary/new',
    loader: () =>
      import('src/view/residuary/form/ResiduaryFormPage'),
    permissionRequired: permissions.residuaryCreate,
    exact: true,
    step: 3,
    section: 'distribution',
    isWasiatRequired: false,
  },
  {
    path: '/residuary/importer',
    loader: () =>
      import(
        'src/view/residuary/importer/ResiduaryImporterPage'
      ),
    permissionRequired: permissions.residuaryImport,
    exact: true,
    section: 'distribution',
  },
  {
    path: '/residuary/:id/edit',
    loader: () =>
      import('src/view/residuary/form/ResiduaryFormPage'),
    permissionRequired: permissions.residuaryEdit,
    exact: true,
    step: 3,
    section: 'distribution',
    isWasiatRequired: false,
  },
  // {
  //   path: '/residuary/:id',
  //   loader: () =>
  //     import('src/view/residuary/view/ResiduaryViewPage'),
  //   permissionRequired: permissions.residuaryRead,
  //   exact: true,
  //   section: 'distribution',
  // },
  {
    path: '/legal-guardian',
    loader: () =>
      import('src/view/guardians/form/GuardiansFormPage'),
    permissionRequired: permissions.legalGuardianRead,
    exact: true,
    step: 5,
    section: 'legalGuardian',
  },
  {
    path: '/legal-guardian/new',
    loader: () =>
      import('src/view/guardians/form/GuardiansFormPage'),
    permissionRequired: permissions.legalGuardianCreate,
    step: 5,
    exact: true,
    section: 'legalGuardian',
  },
  {
    path: '/legal-guardian/:id/edit',
    loader: () =>
      import('src/view/guardians/form/GuardiansFormPage'),
    permissionRequired: permissions.legalGuardianEdit,
    step: 5,
    exact: true,
    section: 'legalGuardian',
  },
  {
    path: '/pledge',
    loader: () =>
      import('src/view/pledge/list/PledgeListPage'),
    permissionRequired: permissions.pledgeRead,
    exact: true,
    step: 8,
    section: 'pledge',
    isWasiatRequired: false,
  },
  {
    path: '/pledge/new',
    loader: () =>
      import('src/view/pledge/form/PledgeFormPage'),
    permissionRequired: permissions.pledgeCreate,
    exact: true,
    section: 'pledge',
  },

  {
    path: '/pledge/importer',
    loader: () =>
      import('src/view/pledge/importer/PledgeImporterPage'),
    permissionRequired: permissions.pledgeImport,
    exact: true,
  },
  {
    path: '/pledge/:id/edit',
    loader: () =>
      import('src/view/pledge/form/PledgeFormPage'),
    permissionRequired: permissions.pledgeEdit,
    exact: true,
    section: 'pledge',
  },
  {
    path: '/pledge/:id',
    loader: () =>
      import('src/view/pledge/view/PledgeViewPage'),
    permissionRequired: permissions.pledgeRead,
    exact: true,
    section: 'pledge',
  },

  {
    path: '/Export',
    loader: () => import('src/view/export/ExportPage'),
    permissionRequired: permissions.Export,
    // step: 10,
    section: 'summary',
  },
  {
    path: '/Export_History',
    loader: () =>
      import('src/view/export/History/Export_History'),
    permissionRequired: permissions.Export,
    // step: 11,
    section: 'summary',
  },
  {
    path: '/Uplaod_Signed_Document/:id',
    loader: () =>
      import(
        'src/view/export/History/Upload_Signed_Document'
      ),
    permissionRequired: permissions.Export,
    section: 'summary',
  },
  // {
  //   path: '/promotion',
  //   loader: () =>
  //     import('src/view/promotion/list/PromotionListPage'),
  //   permissionRequired: permissions.promotionRead,
  //   exact: true,
  // },
  // {
  //   path: '/promotion/new',
  //   loader: () =>
  //     import('src/view/promotion/form/PromotionFormPage'),
  //   permissionRequired: permissions.promotionCreate,
  //   exact: true,
  // },
  // {
  //   path: '/promotion/importer',
  //   loader: () =>
  //     import(
  //       'src/view/promotion/importer/PromotionImporterPage'
  //     ),
  //   permissionRequired: permissions.promotionImport,
  //   exact: true,
  // },
  // {
  //   path: '/promotion/:id/edit',
  //   loader: () =>
  //     import('src/view/promotion/form/PromotionFormPage'),
  //   permissionRequired: permissions.promotionEdit,
  //   exact: true,
  // },
  // {
  //   path: '/promotion/:id',
  //   loader: () =>
  //     import('src/view/promotion/view/PromotionViewPage'),
  //   permissionRequired: permissions.promotionRead,
  //   exact: true,
  // },

  {
    path: '/location',
    loader: () =>
      import('src/view/location/list/LocationListPage'),
    permissionRequired: permissions.locationRead,
    exact: true,
  },

  {
    path: '/location/new',
    loader: () =>
      import('src/view/location/form/LocationFormPage'),
    permissionRequired: permissions.locationCreate,
    exact: true,
  },
  {
    path: '/location/importer',
    loader: () =>
      import(
        'src/view/location/importer/LocationImporterPage'
      ),
    permissionRequired: permissions.locationImport,
    exact: true,
  },
  {
    path: '/location/:id/edit',
    loader: () =>
      import('src/view/location/form/LocationFormPage'),
    permissionRequired: permissions.locationEdit,
    exact: true,
  },
  {
    path: '/location/:id',
    loader: () =>
      import('src/view/location/view/LocationViewPage'),
    permissionRequired: permissions.locationRead,
    exact: true,
  },

  // {
  //   path: '/asset-category',
  //   loader: () =>
  //     import(
  //       'src/view/assetCategory/list/AssetCategoryListPage'
  //     ),
  //   permissionRequired: permissions.assetCategoryRead,
  //   exact: true,
  // },
  // {
  //   path: '/asset-category/new',
  //   loader: () =>
  //     import(
  //       'src/view/assetCategory/form/AssetCategoryFormPage'
  //     ),
  //   permissionRequired: permissions.assetCategoryCreate,
  //   exact: true,
  // },
  // {
  //   path: '/asset-category/importer',
  //   loader: () =>
  //     import(
  //       'src/view/assetCategory/importer/AssetCategoryImporterPage'
  //     ),
  //   permissionRequired: permissions.assetCategoryImport,
  //   exact: true,
  // },
  // {
  //   path: '/asset-category/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/assetCategory/form/AssetCategoryFormPage'
  //     ),
  //   permissionRequired: permissions.assetCategoryEdit,
  //   exact: true,
  // },
  // {
  //   path: '/asset-category/:id',
  //   loader: () =>
  //     import(
  //       'src/view/assetCategory/view/AssetCategoryViewPage'
  //     ),
  //   permissionRequired: permissions.assetCategoryRead,
  //   exact: true,
  // },

  {
    path: '/contact-list',
    loader: () =>
      import(
        'src/view/contactList/list/ContactListListPage'
      ),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'contactList',
  },
  {
    path: '/contact-list/new',
    loader: () =>
      import(
        'src/view/contactList/form/ContactListFormPage'
      ),
    permissionRequired: permissions.contactListCreate,
    exact: true,
    section: 'contactList',
  },
  {
    path: '/contact-list/importer',
    loader: () =>
      import(
        'src/view/contactList/importer/ContactListImporterPage'
      ),
    permissionRequired: permissions.contactListImport,
    exact: true,
    section: 'contactList',
  },
  {
    path: '/contact-list/:id/edit',
    loader: () =>
      import(
        'src/view/contactList/form/ContactListFormPage'
      ),
    permissionRequired: permissions.contactListEdit,
    exact: true,
    section: 'contactList',
  },
  {
    path: '/contact-list/:id',
    loader: () =>
      import(
        'src/view/contactList/view/ContactListViewPage'
      ),
    permissionRequired: permissions.contactListRead,
    exact: true,
    section: 'contactList',
  },
  // {
  //   path: '/marketing',
  //   loader: () =>
  //     import('src/view/marketing/MarketingPage'),
  //   permissionRequired: null,
  // },
  // {
  //   path: '/videoChat',
  //   loader: () =>
  //     import('src/view/videoChat/list/VideoChatListPage'),
  //   permissionRequired: permissions.videoChatRead,
  //   exact: true,
  // },
  // {
  //   path: '/videoAttestation',
  //   loader: () =>
  //     import(
  //       'src/view/videoAttestation/VideoAttestationListPage'
  //     ),
  //   permissionRequired: permissions.videoChatRead,
  //   exact: true,
  // },
  // {
  //   path: '/videoChat',
  //   loader: () =>
  //     import('src/view/videoAttestation/VideoAttestationListPage'),
  //   permissionRequired: permissions.videoChatRead,
  //   exact: true,
  // },
  // {
  //   path: '/videoChat/new',
  //   loader: () =>
  //     import('src/view/videoChat/form/VideoChatForm'),
  //   permissionRequired: permissions.videoChatCreate,
  //   exact: true,
  // },
  // {
  //   path: '/videoChat/:id',
  //   loader: () =>
  //     import('src/view/videoChat/view/VideoChatViewPage'),
  //   permissionRequired: permissions.videoChatRead,
  //   exact: true,
  // },
  {
    path: '/notification',
    loader: () =>
      import('src/view/notification/list/NotificationPage'),
    permissionRequired: permissions.notificationRead,
    exact: true,
  },
  // {
  //   path: '/liquidity-asset',
  //   loader: () =>
  //     import(
  //       'src/view/liquidAsset/list/LiquidAssetListPage'
  //     ),
  //   permissionRequired: permissions.liquidAssetRead,
  //   exact: true,
  //   // step: 8,
  //   section: 'liquidAsset',
  // },
  // {
  //   path: '/liquid-asset',
  //   loader: () =>
  //     import(
  //       'src/view/liquidAsset/list/LiquidAssetListPage'
  //     ),
  //   permissionRequired: permissions.liquidAssetRead,
  //   exact: true,
  //   // step: 8,
  //   section: 'liquidAsset',
  // },
  // {
  //   path: '/liquid-asset/new',
  //   loader: () =>
  //     import(
  //       'src/view/liquidAsset/form/LiquidAssetFormPage'
  //     ),
  //   permissionRequired: permissions.liquidAssetCreate,
  //   exact: true,
  //   section: 'liquidAsset',
  // },
  // {
  //   path: '/liquid-asset/importer',
  //   loader: () =>
  //     import(
  //       'src/view/liquidAsset/importer/LiquidAssetImporterPage'
  //     ),
  //   permissionRequired: permissions.liquidAssetImport,
  //   exact: true,
  //   section: 'liquidAsset',
  // },
  // {
  //   path: '/liquid-asset/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/liquidAsset/form/LiquidAssetFormPage'
  //     ),
  //   permissionRequired: permissions.liquidAssetEdit,
  //   exact: true,
  //   section: 'liquidAsset',
  // },
  // {
  //   path: '/liquid-asset/:id',
  //   loader: () =>
  //     import(
  //       'src/view/liquidAsset/view/LiquidAssetViewPage'
  //     ),
  //   permissionRequired: permissions.liquidAssetRead,
  //   exact: true,
  //   section: 'liquidAsset',
  // },

  {
    path: '/health-info',
    loader: () =>
      import('src/view/healthInfo/list/HealthInfoListPage'),
    permissionRequired: permissions.healthInfoRead,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/new',
    loader: () =>
      import('src/view/healthInfo/form/HealthInfoFormPage'),
    permissionRequired: permissions.healthInfoCreate,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/importer',
    loader: () =>
      import(
        'src/view/healthInfo/importer/HealthInfoImporterPage'
      ),
    permissionRequired: permissions.healthInfoImport,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/:id/edit',
    loader: () =>
      import('src/view/healthInfo/form/HealthInfoFormPage'),
    permissionRequired: permissions.healthInfoEdit,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/:id',
    loader: () =>
      import('src/view/healthInfo/view/HealthInfoViewPage'),
    permissionRequired: permissions.healthInfoRead,
    exact: true,
    section: 'healthInfo',
  },
  {
    path: '/health-info/:id/qr-code',
    loader: () =>
      import(
        'src/view/emergencyCards/public/EmergencyCardQRCode'
      ),
    permissionRequired: permissions.healthInfoRead,
    exact: true,
    section: 'healthInfo',
  },

  {
    path: '/emergency-card/order/:id',
    loader: () =>
      import(
        'src/view/emergencyCardOrder/view/EmergencyCardOrderViewPage'
      ),
    permissionRequired: permissions.emergencyCardOrderRead,
    exact: true,
    section: 'emergencyCard',
  },
  {
    path: '/emergency-card/order',
    loader: () =>
      import(
        'src/view/emergencyCardOrder/list/EmergencyCardOrderListPage'
      ),
    permissionRequired: permissions.emergencyCardOrderRead,
    exact: true,
    section: 'emergencyCard',
  },
  {
    path: '/emergency-card',
    loader: () =>
      import(
        'src/view/emergencyCards/view/EmergencyCardViewPage'
      ),
    exact: true,
  },
  {
    path: '/emergency-card/purchase',
    loader: () =>
      import(
        'src/view/emergencyCards/form/EmergencyCardOrderForm'
      ),
    permissionRequired:
      permissions.emergencyCardOrderCreate,
    exact: true,
  },
  // {
  //   path: '/emergency-card/new',
  //   loader: () =>
  //     import(
  //       'src/view/emergencyCard/form/EmergencyCardFormPage'
  //     ),
  //   permissionRequired: permissions.emergencyCardCreate,
  //   exact: true,
  //   section: 'emergencyCard',
  // },
  // {
  //   path: '/emergency-card/importer',
  //   loader: () =>
  //     import(
  //       'src/view/emergencyCard/importer/EmergencyCardImporterPage'
  //     ),
  //   permissionRequired: permissions.emergencyCardImport,
  //   exact: true,
  //   section: 'emergencyCard',
  // },
  // {
  //   path: '/emergency-card/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/emergencyCard/form/EmergencyCardFormPage'
  //     ),
  //   permissionRequired: permissions.emergencyCardEdit,
  //   exact: true,
  //   section: 'emergencyCard',
  // },
  // {
  //   path: '/emergency-card/:id',
  //   loader: () =>
  //     import(
  //       'src/view/emergencyCard/view/EmergencyCardViewPage'
  //     ),
  //   permissionRequired: permissions.emergencyCardRead,
  //   exact: true,
  //   section: 'emergencyCard',
  // },
  // {
  //   path: '/emergency-card/:id/qr-code',
  //   loader: () =>
  //     import(
  //       'src/view/emergencyCard/qrcode/EmergencyCardQRCode'
  //     ),
  //   permissionRequired: permissions.emergencyCardRead,
  //   exact: true,
  //   section: 'emergencyCard',
  // },
  // {
  //   path: '/cassistant',
  //   loader: () =>
  //     import(
  //       'src/view/contactList/list/ContactListListPage'
  //     ),
  //   permissionRequired: permissions.contactListRead,
  //   exact: true,
  // },
  // {
  //   path: '/assistant',
  //   loader: () =>
  //     import('src/view/assistant/list/AssistantListPage'),
  //   permissionRequired: permissions.userRead,
  //   exact: true,
  // },
  // {
  //   path: '/assistant/new',
  //   loader: () =>
  //     import('src/view/assistant/form/AssistantFormPage'),
  //   permissionRequired: permissions.assistantCreate,
  //   exact: true,
  // },
  // {
  //   path: '/assistant/:id/edit',
  //   loader: () =>
  //     import('src/view/assistant/form/AssistantFormPage'),
  //   permissionRequired: permissions.assistantEdit,
  //   exact: true,
  // },
  // {
  //   path: '/assistant/:id',
  //   loader: () =>
  //     import('src/view/assistant/view/AssistantViewPage'),
  //   permissionRequired: permissions.assistantRead,
  //   exact: true,
  // },
  // {
  //   path: '/documentation',
  //   loader: () =>
  //     import(
  //       'src/view/documentation/list/DocumentationListPage'
  //     ),
  //   permissionRequired: permissions.documentationRead,
  //   exact: true,
  // },
  // {
  //   path: '/documentation/new',
  //   loader: () =>
  //     import(
  //       'src/view/documentation/form/DocumentationFormPage'
  //     ),
  //   permissionRequired: permissions.documentationCreate,
  //   exact: true,
  // },
  // {
  //   path: '/documentation/importer',
  //   loader: () =>
  //     import(
  //       'src/view/documentation/importer/DocumentationImporterPage'
  //     ),
  //   permissionRequired: permissions.documentationImport,
  //   exact: true,
  // },
  // {
  //   path: '/documentation/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/documentation/form/DocumentationFormPage'
  //     ),
  //   permissionRequired: permissions.documentationEdit,
  //   exact: true,
  // },
  // {
  //   path: '/documentation/:id',
  //   loader: () =>
  //     import(
  //       'src/view/documentation/view/DocumentationViewPage'
  //     ),
  //   permissionRequired: permissions.documentationRead,
  //   exact: true,
  // },
  {
    path: '/insurance',
    loader: () =>
      import('src/view/insurance/list/InsuranceListPage'),
    permissionRequired: permissions.insuranceRead,
    exact: true,
    section: 'insurance',
  },
  {
    path: '/insurance/new',
    loader: () =>
      import('src/view/insurance/form/InsuranceFormPage'),
    permissionRequired: permissions.insuranceCreate,
    exact: true,
    section: 'insurance',
  },
  {
    path: '/insurance/importer',
    loader: () =>
      import(
        'src/view/insurance/importer/InsuranceImporterPage'
      ),
    permissionRequired: permissions.insuranceImport,
    exact: true,
    section: 'insurance',
  },
  {
    path: '/insurance/:id/edit',
    loader: () =>
      import('src/view/insurance/form/InsuranceFormPage'),
    permissionRequired: permissions.insuranceEdit,
    exact: true,
    section: 'insurance',
  },
  {
    path: '/insurance/:id',
    loader: () =>
      import('src/view/insurance/view/InsuranceViewPage'),
    permissionRequired: permissions.insuranceRead,
    exact: true,
    section: 'insurance',
  },

  {
    path: '/digital',
    loader: () =>
      import('src/view/digital/list/DigitalListPage'),
    permissionRequired: permissions.digitalRead,
    exact: true,
    // step: 7,
    section: 'digital',
  },
  {
    path: '/digital/new',
    loader: () =>
      import('src/view/digital/form/DigitalFormPage'),
    permissionRequired: permissions.digitalCreate,
    exact: true,
    section: 'digital',
  },
  {
    path: '/digital/:id/edit',
    loader: () =>
      import('src/view/digital/form/DigitalFormPage'),
    permissionRequired: permissions.digitalEdit,
    exact: true,
    section: 'digital',
  },
  {
    path: '/digital/:id',
    loader: () =>
      import('src/view/digital/view/DigitalViewPage'),
    permissionRequired: permissions.digitalRead,
    exact: true,
    section: 'digital',
  },
  {
    path: '/solicitor/:id',
    loader: () =>
      import('src/view/solicitor/view/SolicitorViewPage'),
    permissionRequired: permissions.solicitorRead,
    exact: true,
  },
  {
    path: '/solicitor/:id/edit',
    loader: () =>
      import('src/view/solicitor/form/SolicitorFormPage'),
    permissionRequired: permissions.solicitorEdit,
    exact: true,
  },
  {
    path: '/resources',
    loader: () =>
      import('src/view/resources/ResourcesPageWebView'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/share-with-me',
    loader: () =>
      import('src/view/tenant/list/SharedWithMeListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/share-with-me/:id/edit',
    loader: () =>
      import('src/view/tenant/list/SharedWithMeListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/my-vault',
    loader: () =>
      import('src/view/assistant/list/MyVaultListPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/my-vault/:id/edit',
    loader: () =>
      import('src/view/assistant/list/MyVaultListPage'),
    permissionRequired: permissions.userRead,
    //permissionRequired: permissions.assistantEdit,
    exact: true,
  },
  {
    path: '/my-vault/:id/view',
    loader: () =>
      import('src/view/assistant/view/MyVaultViewPage'),
    permissionRequired: permissions.userRead,
    //permissionRequired: permissions.assistantEdit,
    exact: true,
  },

  {
    path: '/vehicle',
    loader: () =>
      import('src/view/vehicle/list/VehicleListPage'),
    permissionRequired: permissions.vehicleRead,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/vehicle/new',
    loader: () =>
      import('src/view/vehicle/form/VehicleFormPage'),
    permissionRequired: permissions.vehicleCreate,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/vehicle/importer',
    loader: () =>
      import(
        'src/view/vehicle/importer/VehicleImporterPage'
      ),
    permissionRequired: permissions.vehicleImport,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/vehicle/:id/edit',
    loader: () =>
      import('src/view/vehicle/form/VehicleFormPage'),
    permissionRequired: permissions.vehicleEdit,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/vehicle/:id',
    loader: () =>
      import('src/view/vehicle/view/VehicleViewPage'),
    permissionRequired: permissions.vehicleRead,
    exact: true,
    section: 'vehicle',
  },
  {
    path: '/pet',
    loader: () => import('src/view/pet/list/PetListPage'),
    permissionRequired: permissions.petRead,
    exact: true,
    section: 'pet',
  },
  {
    path: '/pet/new',
    loader: () => import('src/view/pet/form/PetFormPage'),
    permissionRequired: permissions.petCreate,
    exact: true,
    section: 'pet',
  },
  {
    path: '/pet/importer',
    loader: () =>
      import('src/view/pet/importer/PetImporterPage'),
    permissionRequired: permissions.petImport,
    exact: true,
    section: 'pet',
  },
  {
    path: '/pet/:id/edit',
    loader: () => import('src/view/pet/form/PetFormPage'),
    permissionRequired: permissions.petEdit,
    exact: true,
    section: 'pet',
  },
  {
    path: '/pet/:id',
    loader: () => import('src/view/pet/view/PetViewPage'),
    permissionRequired: permissions.petRead,
    exact: true,
    section: 'pet',
  },
  // {
  //   path: '/trustee',
  //   loader: () =>
  //     import('src/view/trustee/list/TrusteeListPage'),
  //   permissionRequired: permissions.trusteeRead,
  //   exact: true,
  //   section: 'trustee',
  // },
  // {
  //   path: '/trustee/new',
  //   loader: () =>
  //     import('src/view/trustee/form/TrusteeFormPage'),
  //   permissionRequired: permissions.trusteeCreate,
  //   exact: true,
  //   section: 'trustee',
  // },
  // {
  //   path: '/trustees/new',
  //   loader: () =>
  //     import('src/view/trustee/main/TrusteesFormPage'),
  //   permissionRequired: permissions.trusteeCreate,
  //   exact: true,
  //   section: 'trustee',
  // },
  // {
  //   path: '/trustees/:id/edit',
  //   loader: () =>
  //     import('src/view/trustee/main/TrusteesFormPage'),
  //   permissionRequired: permissions.trusteeEdit,
  //   exact: true,
  //   section: 'trustee',
  // },

  // {
  //   path: '/trustee/importer',
  //   loader: () =>
  //     import(
  //       'src/view/trustee/importer/TrusteeImporterPage'
  //     ),
  //   permissionRequired: permissions.trusteeImport,
  //   exact: true,
  //   section: 'trustee',
  // },
  // {
  //   path: '/trustee/:id/edit',
  //   loader: () =>
  //     import('src/view/trustee/form/TrusteeFormPage'),
  //   permissionRequired: permissions.trusteeEdit,
  //   exact: true,
  //   section: 'trustee',
  // },
  // {
  //   path: '/trustee/:id',
  //   loader: () =>
  //     import('src/view/trustee/view/TrusteeViewPage'),
  //   permissionRequired: permissions.trusteeRead,
  //   exact: true,
  //   section: 'trustee',
  // },
  {
    path: '/custody-view',
    loader: () =>
      import('src/view/custody/view/CustodyViewPage'),
    exact: true,
  },
  //#region Hibah
  {
    path: '/legal-guardian_hibah/new',
    loader: () =>
      import('src/view/guardians/form/GuardiansFormPage'),
    permissionRequired: permissions.legalGuardianCreate,
    step: 5,
    exact: true,
    section: 'legalGuardian',
  },
  {
    path: '/legal-guardian_hibah/:id/edit',
    loader: () =>
      import('src/view/guardians/form/GuardiansFormPage'),
    permissionRequired: permissions.legalGuardianEdit,
    step: 5,
    exact: true,
    section: 'legalGuardian',
  },

  {
    path: '/executors_hibah/new',
    loader: () =>
      import('src/view/executor/main/ExecutorsFormPage'),
    permissionRequired: permissions.executorCreate,
    step: 4,
    exact: true,
    section: 'guardians',
  },
  {
    path: '/executors_hibah/:id/edit',
    loader: () =>
      import('src/view/executor/main/ExecutorsFormPage'),
    permissionRequired: permissions.executorEdit,
    step: 4,
    exact: true,
    section: 'guardians',
  },
  {
    path: '/executors_hibah/importer',
    loader: () =>
      import(
        'src/view/executor/importer/ExecutorImporterPage'
      ),
    permissionRequired: permissions.executorImport,
    exact: true,
    section: 'executor',
  },
  {
    path: '/executors_hibah/:id/edit',
    loader: () =>
      import('src/view/executor/form/ExecutorFormPage'),
    permissionRequired: permissions.executorEdit,
    exact: true,
    section: 'guardians',
  },
  {
    path: '/executors_hibah/:id',
    loader: () =>
      import('src/view/executor/view/ExecutorViewPage'),
    permissionRequired: permissions.executorRead,
    exact: true,
    section: 'guardians',
  },
  {
    path: '/witness_hibah/new',
    loader: () =>
      import('src/view/witness/main/WitnesssFormPage'),
    permissionRequired: permissions.witnessCreate,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness_hibah/:id/edit',
    loader: () =>
      import('src/view/witness/main/WitnesssFormPage'),
    permissionRequired: permissions.witnessEdit,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness_hibah/importer',
    loader: () =>
      import(
        'src/view/witness/importer/WitnessImporterPage'
      ),
    permissionRequired: permissions.witnessImport,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness_hibah/:id/edit',
    loader: () =>
      import('src/view/witness/form/WitnessFormPage'),
    permissionRequired: permissions.witnessEdit,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness_hibah/:id',
    loader: () =>
      import('src/view/witness/view/WitnessViewPage'),
    permissionRequired: permissions.witnessRead,
    exact: true,
    section: 'witness',
  },
  //#endregion

  //#region Harta -Witness
  {
    path: '/witnesss_harta/new',
    loader: () =>
      import('src/view/witness/main/WitnesssFormPage'),
    permissionRequired: permissions.witnessCreate,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witnesss_harta/:id/edit',
    loader: () =>
      import('src/view/witness/main/WitnesssFormPage'),
    permissionRequired: permissions.witnessEdit,
    step: 6,
    exact: true,
    section: 'witness',
  },
  {
    path: '/witness_harta/:id',
    loader: () =>
      import('src/view/witness/view/WitnessViewPage'),
    permissionRequired: permissions.witnessRead,
    exact: true,
    section: 'witness',
  },
  //#endregion

  {
    path: '/dependents/:id',
    loader: () =>
      import('src/view/dependent/DependentsFormPage'),
    permissionRequired: permissions.dependentEdit,
    exact: true,
    section: 'dependents',
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
  {
    path: '/auth/signinmfa',
    loader: () => import('src/view/auth/SigninMfaPage'),
  },
  {
    path: '/will_preview/:id',
    loader: () =>
      import('src/view/export/PDFPreview/PDFPreviewPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/planupgrade',
    loader: () => import('src/view/plan/PlanUpgradePage'),
  },
  {
    path: '/auth/email-verification/:emailo',
    loader: ({ params }) => {
      // console.log('Email Verification Route');
      const email = params.emailo;
      // console.log('Email parameter:', email);
      params.emailo = sanitizeObject(params.emailo);
      // console.log('Email parameter sanitized:', params.emailo);

      return import('src/view/auth/EmailVerificationPage');
    },
  },
  {
    path: '/emergency-card/:id/public-info',
    loader: () =>
      import(
        'src/view/emergencyCards/public/EmergencyCardPublic'
      ),
  },
  {
    path: '/plan-invitation/verify-token/view',
    loader: () =>
      import(
        'src/view/planInvitation/view/PlanInvitationViewPage'
      ),
  },
  {
    path: '/plan-invitation/verify-token/form',
    loader: () =>
      import(
        'src/view/planInvitation/view/PlanInvitationFormPage'
      ),
  },
  {
    path: '/videoAttestation/:id/room',
    loader: () =>
      import(
        'src/view/videoAttestation/chatRoom/VideoChatForm'
      ),
  },
  {
    path: '/legacy/confirmation',
    loader: () => import('src/view/user/client/TransferClientConfirmationPage'),
  },
  {
    path: '/downloadSharingFile',
    loader: () => import('src/view/tenant/DownloadSharingFile'),
  },
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/assistant/request',
    loader: () =>
      import('src/view/auth/VerifyEmailFullAccessPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
